.installments-checkboxs .custom-checkbox[data-v-5cc57d6a] {
  display: block;
}[dir] .installments-checkboxs .custom-checkbox[data-v-5cc57d6a] {
  padding: 0px;
  text-align: center;
  margin: 0px 25px;
  padding: 0px !important;
}
[dir] .installments-checkboxs .custom-checkbox label[data-v-5cc57d6a] {
  margin: 0px;
}
.disciplines-course .vs__selected[data-v-5cc57d6a] {
  font-size: 0.9rem !important;
}